<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-07-27 09:42:32
 * @LastEditTime: 2022-09-21 16:26:16
 * @Description: 时间设置
 * @Param:
 * @FilePath: \BusinessPlatform\src\views\admin\globalSettings\timeSetting.vue
 -->


<template>
    <ol class="timeSetting">
        <template v-for="(item, index) in timeSettingList">
            <li :key="`timeSetting_${index}`">
                <h4>{{ item.title }}</h4>
                <div>
                    <h5>{{ item.name }}：</h5>
                    <el-time-picker
                        is-range
                        v-model="item.time"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        placeholder="选择时间范围"
                        value-format="HH:mm:ss"
                        @change="selectTime(item)"
                    >
                    </el-time-picker>
                </div>
            </li>
        </template>
    </ol>
</template>

<script>
import currencyFun from '@/assets/js/currencyFun';

export default {
    // 时间设置
    name: 'time-setting',
    components: {},
    props: {},
    data() {
        return {
            timeSettingList: [{
                title: '统计时间',
                name: '生产统计时间：',
                type: 'setting_product_time_format',
                time: [],
            }],
        };
    },
    watch: {},
    computed: {},
    created() {
        this.timeSettingList.forEach(async item => {
            const newTimeNode = await currencyFun.getTimeNode(item.type, this);
            if (newTimeNode.length > 0) {
                item.time = [newTimeNode.start_time, newTimeNode.end_time];
            } else {
                item.time = ['07:00:00', '07:00:00'];
            }
        });
    },
    mounted() {},
    methods: {
        // 确认时间
        selectTime(item) {
            this.$axios
                .post('/interfaceApi/baseinfo/features_manage/save', {
                    feature_code: item.type,
                    feature_value: `{"start_time":"${item.time[0]}","end_time":"${item.time[1]}"}`,
                })
                .then(res => {
                    this.$message({
                        message: res,
                        type: 'success',
                    });
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.message);
                });
        },
    },
};
</script>

<style scoped lang="stylus">
.timeSetting
    width 100%
    padding 0.14rem
    li
        border 1px solid #D7D7D7
        h4
            font-size 0.18rem
            background #EDF0F5
            color #022782
            line-height 0.44rem
            padding-left 0.2rem
            font-weight 600
        >div
            display flex
            padding 0.2rem
            h5
                font-size 0.16rem
                color #333333
                line-height 0.36rem

</style>
